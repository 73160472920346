import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import shortId from 'shortid';
import { Layout } from './Layout';
import { Box, Flex } from './elements';
import { RichText } from '../shared/RichText';

const Background = styled(Box)`
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const AvatarWrap = styled(Box)`
  overflow: hidden;
  @media (min-width: 768px) {
    float: left;
  }
`;

const AboutPage = ({ data }) => {
  const { site_logo: siteLogo } = data.prismicNavigation.data;
  const { localFile: bgLocalFile } = data.prismicNavigation.data.bg_nav;
  const { body: navigation } = data.prismicNavigation.data;
  const navigationPack = { navigation, bgLocalFile };
  const items = data.prismicPage.data.body;
  const author = items[0].primary;
  const background = items[1].primary;
  const {
    section_title: sectionTitle,
    section_content: sectionContent
  } = items[2].primary;

  return (
    <>
      <Layout navigationPack={navigationPack} siteLogo={siteLogo}>
        <Background alignSelf="center" mx="auto">
          <Image
            alt={background.page_image.alt}
            fluid={background.page_image.localFile.childImageSharp.fluid}
          />
        </Background>
        <Flex
          flexDirection={{ _: 'column', md: 'row' }}
          justifyContent="space-between"
          width="100%"
          minHeight="100%"
          height="100%"
          style={{ whiteSpace: 'nowrap', overflowY: 'scroll' }}
          p={{ _: '0', md: '32px' }}
          pb="20px"
          mb={{ _: '-40px', md: 0 }}
        >
          <Box
            style={{
              position: 'relative',
              wordBreak: 'break-all',
              whiteSpace: 'normal'
            }}
            width="100%"
            height="100%"
            p={4}
          >
            <AvatarWrap height={400} width={300} mr={3} mb={3}>
              <Image
                alt={author.page_image.alt}
                fluid={author.page_image.localFile.childImageSharp.fluid}
                loading="lazy"
              />
            </AvatarWrap>
            {sectionTitle.raw.map(item => (
              <RichText key={shortId.generate()} raw={item} />
            ))}
            {sectionContent.raw.map(item => (
              <RichText key={shortId.generate()} raw={item} />
            ))}
          </Box>
        </Flex>
      </Layout>
    </>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const query = graphql`
  fragment AboutContentFragment on Query {
    prismicPage(uid: { eq: "about" }) {
      data {
        title {
          text
        }
        body {
          ... on PrismicPageBodyPageImage {
            id
            primary {
              page_image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              image_role
            }
          }
          ... on PrismicPageBodyPageContentText {
            id
            primary {
              section_title {
                raw {
                  text
                  type
                }
              }
              section_content {
                raw {
                  text
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;
