import { graphql } from 'gatsby';
import AboutPage from '../components/2.AboutPage';

export default AboutPage;

export const query = graphql`
  {
    ...LayoutFragment
    ...AboutContentFragment
  }
`;
